import React from 'react';
import PropTypes from 'prop-types';
import { SIZES } from 'lib/constants';
import { GroupedOptionsPropType, StandardOptionsPropType } from 'lib/propTypes';
import ReactSelect from 'react-select';
import { ReactSelectStyles } from '../ReactSelectStyles';

function Select(props) {
  const derivedValue = props.options.find((option) => option.value === props.value) || null;

  return (
    <ReactSelect
      menuPosition="fixed"
      styles={ReactSelectStyles(props.size)}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      value={derivedValue}
    />
  );
}

Select.defaultProps = {
  size: SIZES.md,
  options: [],
  value: '',
};

Select.propTypes = {
  size: PropTypes.oneOf(Object.values(SIZES)),
  options: PropTypes.oneOfType([StandardOptionsPropType, GroupedOptionsPropType]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
    label: PropTypes.string.isRequired,
  })]),
};

export default Select;
