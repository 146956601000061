import React from 'react';
import PropTypes from 'prop-types';

/**
 * Safely display new lines from a user-generated string.
 * Useful for rendering new lines in things like comments or messages.
 */
function MultilineText({ text, className }) {
  return (
    <p className={className} style={{ whiteSpace: 'pre-line' }}>
      { text }
    </p>
  );
}

MultilineText.defaultProps = {
  className: '',
};

MultilineText.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default MultilineText;
