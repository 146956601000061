import React from 'react';
import PropTypes from 'prop-types';
import { formatDistanceToNow, parseISO } from 'date-fns';

function SubtitleDate({ date, className }) {
  if (!date) return null;

  return (
    <span className={`font-noto fw-medium text-small text-accent-grey ${className}`}>{(formatDistanceToNow(parseISO(date), { addSuffix: true }))}</span>
  );
}

SubtitleDate.defaultProps = {
  date: null,
  className: '',
};

SubtitleDate.propTypes = {
  date: PropTypes.string,
  className: PropTypes.string,
};

export default SubtitleDate;
