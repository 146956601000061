import React from 'react';
import PropTypes from 'prop-types';
import { AnswerPropType, CommentPropType } from 'lib/propTypes';
import { Button } from 'react-bootstrap';
import SanyasIcon from 'components/shared/SanyasIcon';

function MarkAsReadButton({ post, markAsRead }) {
  return (
    <Button onClick={markAsRead} variant="plain" size="sm" className="fw-semibold text-uppercase">
      {post.notificationCleared ? (
        <>
          <SanyasIcon name="checkCircle" className="me-1" />
          Read
        </>
      ) : (
        <>
          <SanyasIcon name="square" size="xs" className="me-1" style={{ marginBottom: 2 }} />
          Unread
        </>
      )}
    </Button>
  );
}

MarkAsReadButton.defaultProps = {
  post: null,
  markAsRead: null,
};

MarkAsReadButton.propTypes = {
  post: PropTypes.oneOfType([
    CommentPropType,
    AnswerPropType,
  ]),
  markAsRead: PropTypes.func,
};

export default MarkAsReadButton;
