import _flatten from 'lodash/flatten';
import _orderBy from 'lodash/orderBy';
import { createSelector } from 'reselect';
import { add, getHours, getMinutes, parseISO } from 'date-fns';
import formatInTimeZone from 'date-fns-tz/formatInTimeZone';
import { SORT_ORDER_TYPES } from './reducer';

export const fetchDashboard = (state) => state.facilitatorDashboard?.subGroups;
export const dashboardSortOrder = (state) => state.facilitatorDashboard?.sortOrder;

export const facilitatorIds = (state) => state.facilitatorDashboard?.facilitators.allIds;
export const facilitatorById = (state) => state.facilitatorDashboard?.facilitators.byId;

export const fetchSubGroupData = (state, id) => state.facilitatorDashboard?.subGroups?.[id];

const selectUserTasks = (state) => state.facilitatorDashboard?.userTasks;

function calculateCloseSessionAt({ groupCloseSessionAt, groupExtendedUntil, extendedParticipants }) {
  if (groupExtendedUntil && extendedParticipants?.length) {
    const close = parseISO(groupCloseSessionAt);
    const extended = add(
      parseISO(groupExtendedUntil),
      { hours: getHours(close), minutes: getMinutes(close) },
    );
    // add character (e.g., 0) to ensure extended is sorted after close
    return formatInTimeZone(extended, 'UTC', "y-MM-dd'T'HH:mm:ss.SSSXX0");
  }

  return groupCloseSessionAt;
}

function calculateTotalsForSubGroup(subGroupData) {
  function sumTotals(prevTotal, obj) {
    return prevTotal + obj.notificationTotal;
  }
  // Some groups do not have discussion boards
  const dbNotifications = subGroupData.discussionBoardNotifications ? Object.values(subGroupData.discussionBoardNotifications) : [];
  const discussionBoardTotals = dbNotifications.reduce(sumTotals, 0);
  // Some groups do not have journals
  const journalNotifications = subGroupData.journalNotifications ? Object.values(subGroupData.journalNotifications) : [];
  const journalTotals = journalNotifications.reduce(sumTotals, 0);

  return { discussionBoards: discussionBoardTotals, journals: journalTotals, total: discussionBoardTotals + journalTotals };
}

export const selectFacilitators = createSelector(
  facilitatorIds,
  facilitatorById,
  (ids, byId) => ids.map((id) => byId[id]),
);

export const activatedFacilitators = createSelector(
  selectFacilitators,
  (facilitators) => facilitators.filter((v) => v.activation === 'activated'),
);

export const notificationTotalsForSubGroup = createSelector(
  fetchSubGroupData,
  calculateTotalsForSubGroup,
);

export const notificationTotals = createSelector(
  fetchDashboard,
  (dashboardData) => {
    if (!dashboardData) return {};
    return Object.values(dashboardData).reduce((result, value) => {
      const totals = calculateTotalsForSubGroup(value);
      return {
        discussionBoards: result.discussionBoards + totals.discussionBoards,
        journals: result.journals + totals.journals,
        total: result.total + totals.total,
      };
    }, { discussionBoards: 0, journals: 0, total: 0 });
  },
);

/**
 * Different courses have different numbers of journals.
 * This selector returns the maximum number of journals for a given collection
 * of pods.
 */
export const selectMaxJournals = createSelector(
  fetchDashboard,
  (dashboardData) => {
    if (!dashboardData) return 0;
    const numJournalsPerPod = Object.values(dashboardData)
      .map((pod) => {
        if (!pod.journalNotifications) return 0;
        return Object.keys(pod.journalNotifications).length;
      });
    if (numJournalsPerPod.length === 0) return 0;
    return Math.max(...numJournalsPerPod);
  },
);

/**
 * Similar to maxJournals.
 */
export const selectMaxDBs = createSelector(
  fetchDashboard,
  (dashboardData) => {
    if (!dashboardData) return 0;
    const numDBsPerPod = Object.values(dashboardData)
      .map((pod) => {
        if (!pod.discussionBoardNotifications) return 0;
        return Object.keys(pod.discussionBoardNotifications).length;
      });
    if (numDBsPerPod.length === 0) return 0;
    return Math.max(...numDBsPerPod);
  },
);

export const selectOpsSubGroups = createSelector(
  fetchDashboard,
  (dashboardData) => {
    if (!dashboardData) return null;
    return Object.values(dashboardData).filter((sg) => sg.isOps);
  },
);

export const sortedSubGroups = createSelector(
  fetchDashboard,
  dashboardSortOrder,
  (dashboardData, sortOrder) => {
    if (!dashboardData) return null;

    const dashboardDataArray = Object.values(dashboardData).filter((sg) => !sg.tempRecord).map((sg) => ({
      ...sg,
      calculatedCloseSessionAt: calculateCloseSessionAt({
        groupCloseSessionAt: sg.groupCloseSessionAt,
        groupExtendedUntil: sg.groupExtendedUntil,
        extendedParticipants: sg.extendedParticipants,
      }),
      notificationTotal: calculateTotalsForSubGroup(sg).total,
    }));

    switch (sortOrder) {
      case SORT_ORDER_TYPES.UNREAD_POSTS.value:
        return _orderBy(dashboardDataArray, 'notificationTotal', 'desc');
      case SORT_ORDER_TYPES.START_DATE_NEWEST_FIRST.value:
        return _orderBy(dashboardDataArray, 'groupBeginSessionAt', 'desc');
      case SORT_ORDER_TYPES.CLOSING_DATE_OLDEST_FIRST.value:
        return _orderBy(dashboardDataArray, 'calculatedCloseSessionAt', 'asc');
      case SORT_ORDER_TYPES.CLOSING_DATE_NEWEST_FIRST.value:
        return _orderBy(dashboardDataArray, 'calculatedCloseSessionAt', 'desc');
      default: // SORT_ORDER_TYPES.START_DATE_OLDEST_FIRST.value
        return _orderBy(dashboardDataArray, 'groupBeginSessionAt', 'asc');
    }
  },
);

const sortedNotifications = (notificationStore) => createSelector(
  fetchDashboard,
  dashboardSortOrder,
  (subGroups, sortOrder) => {
    if (!subGroups) return null;

    const notificationId = notificationStore === 'discussionBoardNotifications' ? 'discussionBoardId' : 'journalTaskId';

    const notifications = _flatten(Object.values(subGroups).filter((sg) => !sg.tempRecord).map(
      (sg) => sg[notificationStore] && Object.values(sg[notificationStore]),
    ))
      .map((notification) => {
        const sg = subGroups[notification.subGroupId];
        return ({
          ...notification,
          key: `${sg.groupId}-${notification.subGroupId}-${notification[notificationId]}`,
          subGroupName: sg.subGroupName,
          groupId: sg.groupId,
          groupName: sg.groupName,
          groupBeginSessionAt: sg.groupBeginSessionAt,
          groupCloseSessionAt: sg.groupCloseSessionAt,
          groupExtendedUntil: sg.groupExtendedUntil,
          calculatedCloseSessionAt: calculateCloseSessionAt({
            groupCloseSessionAt: sg.groupCloseSessionAt,
            groupExtendedUntil: sg.groupExtendedUntil,
            extendedParticipants: sg.extendedParticipants,
          }),
          responseRate: (notification.responseCount ?? 0) / sg.participantCount,
        });
      });

    switch (sortOrder) {
      case SORT_ORDER_TYPES.UNREAD_POSTS.value:
        return _orderBy(notifications, 'notificationTotal', 'desc');
      case SORT_ORDER_TYPES.START_DATE_NEWEST_FIRST.value:
        return _orderBy(notifications, ['groupBeginSessionAt', 'subGroupId', 'position'], ['desc', 'desc', 'asc']);
      case SORT_ORDER_TYPES.CLOSING_DATE_OLDEST_FIRST.value:
        return _orderBy(notifications, ['calculatedCloseSessionAt', 'subGroupId', 'position'], ['asc', 'desc', 'asc']);
      case SORT_ORDER_TYPES.CLOSING_DATE_NEWEST_FIRST.value:
        return _orderBy(notifications, ['calculatedCloseSessionAt', 'subGroupId', 'position'], ['desc', 'desc', 'asc']);
      default: // SORT_ORDER_TYPES.START_DATE_OLDEST_FIRST.value
        return _orderBy(notifications, ['groupBeginSessionAt', 'subGroupId', 'position'], ['asc', 'desc', 'asc']);
    }
  },
);
export const sortedDBNotifications = sortedNotifications('discussionBoardNotifications');
export const sortedJournalNotifications = sortedNotifications('journalNotifications');

export const selectNoteBySubgroupAndDiscussionTask = createSelector(
  fetchDashboard,
  (_, { subGroupId, taskId }) => ({ subGroupId, taskId }),
  (dashboardData, { subGroupId, taskId }) => {
    if (!dashboardData) return null;
    return dashboardData[subGroupId]?.discussionBoardNotifications?.[taskId]?.note;
  },
);

export const selectNoteBySubgroupAndJournalTask = createSelector(
  fetchDashboard,
  (_, { subGroupId, taskId }) => ({ subGroupId, taskId }),
  (dashboardData, { subGroupId, taskId }) => {
    if (!dashboardData) return null;
    return dashboardData[subGroupId]?.journalNotifications?.[taskId]?.note;
  },
);

export const selectNoteByUserTask = createSelector(
  selectUserTasks,
  (_, id) => (id),
  (userTasks, id) => {
    if (!userTasks || !id) return null;
    return userTasks[id]?.note;
  },
);
