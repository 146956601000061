import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { buildRoutePath } from 'lib/routerHelpers';
import { FACILITATOR_DISCUSSION_BOARD_COMMENTS_PATH, FACILITATOR_JOURNAL_ANSWERS_PATH } from 'lib/routerPaths';
import { LOAD_JOURNAL_AND_DISCUSSION_BOARDS } from 'store/groupMemberships/actions';
import List from 'components/shared/ActionsMenu/List';
import NotificationDot from 'components/shared/NotificationDot';
import SanyasIcon from 'components/shared/SanyasIcon';

function formatLabel(task) {
  if (!task) return '';

  const text = `${task.internalName}: ${task.name}`;

  return ({
    text,
    component: (
      <>
        {text}
        {(task.notification || task.isFlagged) && (
          <span className="ms-1">
            {task.isFlagged && <SanyasIcon name="flag" size="sm" className="ms-1 text-danger" />}
            {task.notification && <NotificationDot className="ms-1" age={task.notification.age} primary={task.notification.primary} />}
          </span>
        )}
      </>
    ),
  });
}

function JournalAndDiscussionBoards({ groupMembershipId, groupId, subGroupId, userId }) {
  const { discussionBoards, journalTasks } = useSelector((state) => state.groupMemberships.journalsDiscussionBoards);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(LOAD_JOURNAL_AND_DISCUSSION_BOARDS.request({ groupMembershipId, userId }));
  }, [dispatch, groupMembershipId, userId]);

  const menuItems = useMemo(() => {
    const items = [];
    if (!discussionBoards && !journalTasks) return [];
    discussionBoards.forEach((task) => {
      items.push({
        label: formatLabel(task),
        to: buildRoutePath(FACILITATOR_DISCUSSION_BOARD_COMMENTS_PATH, { id: groupId, subGroupId, taskId: task.id }),
        icon: 'chat',
      });
    });
    journalTasks.forEach((task) => {
      items.push({
        label: formatLabel(task),
        to: buildRoutePath(FACILITATOR_JOURNAL_ANSWERS_PATH, { id: groupId, subGroupId, taskId: task.id, groupMembershipId }),
        icon: 'book',
      });
    });
    return items;
  }, [discussionBoards, groupId, groupMembershipId, journalTasks, subGroupId]);

  return (
    <ul className="list-unstyled">
      <List items={menuItems} />
    </ul>
  );
}

JournalAndDiscussionBoards.defaultProps = {
  subGroupId: undefined,
};

JournalAndDiscussionBoards.propTypes = {
  groupMembershipId: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
  groupId: PropTypes.number.isRequired,
  subGroupId: PropTypes.number,
};

export default JournalAndDiscussionBoards;
