import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { buildRoutePath } from 'lib/routerHelpers';
import { FACILITATOR_PARTICIPANTS_SHOW_PATH } from 'lib/routerPaths';

function ParticipantLink({ groupId, groupMembershipId, participantName }) {
  const facilitatedGroups = useSelector((state) => state.currentUser?.facilitatedGroups);

  if (facilitatedGroups?.includes(Number(groupId))) {
    return (
      <a
        href={buildRoutePath(FACILITATOR_PARTICIPANTS_SHOW_PATH, { groupMembershipId })}
        className="btn-plain"
        target="_blank"
        rel="noreferrer"
      >
        {participantName}
      </a>
    );
  }

  return (<span className="text-primary">{participantName}</span>);
}

ParticipantLink.propTypes = {
  groupId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  groupMembershipId: PropTypes.number.isRequired,
  participantName: PropTypes.string.isRequired,
};

export default ParticipantLink;
