import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { REDUX_STATUS } from 'lib/constants';
import { DELETE_COMMENT } from 'store/facilitatorDashboard/actions';
import { Dropdown } from 'react-bootstrap';
import Confirmation from 'components/shared/Modal/Confirmation';
import FlagForm from 'components/facilitator/Flag/FlagForm';
import SanyasIcon from '../SanyasIcon';
import './styles.scss';

function CommentCardDropdown({ disabled, setIsEditable, commentId, isJournalComment }) {
  const dispatch = useDispatch();
  const [showFlagsModal, setShowFlagsModal] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const status = useSelector((state) => state.facilitatorDashboard.status);
  const comment = useSelector((state) => state.facilitatorDashboard.commentsById[commentId]);

  useEffect(() => {
    if (status === REDUX_STATUS.SUCCESS) {
      setShowFlagsModal(false);
    }
  }, [status]);

  const handleConfirmDeleteClose = useCallback(() => {
    setConfirmDeleteOpen(false);
  }, []);

  const handleDelete = useCallback(() => {
    dispatch(DELETE_COMMENT.request({ id: commentId }));
  }, [commentId, dispatch]);

  return (
    <>
      <Dropdown>
        <Dropdown.Toggle disabled={disabled} variant="alt-secondary" size="sm" bsPrefix="no-arrow">
          <SanyasIcon name="meatball" size="xl" style={{ top: 3 }} />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {!isJournalComment && (
            <Dropdown.Item as="button" onClick={() => setShowFlagsModal(!showFlagsModal)} disabled={comment.isFacilitatorComment}>
              Flag
            </Dropdown.Item>
          )}
          <Dropdown.Item as="button" onClick={() => setIsEditable(true)}>
            Edit
          </Dropdown.Item>
          <Dropdown.Item as="button" onClick={() => setConfirmDeleteOpen(true)}>
            Delete
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <Confirmation
        title="Delete Post?"
        open={confirmDeleteOpen}
        close={handleConfirmDeleteClose}
        text="Are you sure you want to delete this post? This action cannot be undone."
        confirmText="Delete"
        confirmAction={handleDelete}
        cancelText={null}
      />

      <FlagForm post={comment} show={showFlagsModal} onClose={setShowFlagsModal} />
    </>
  );
}

CommentCardDropdown.defaultProps = {
  disabled: false,
  isJournalComment: undefined,
};

CommentCardDropdown.propTypes = {
  setIsEditable: PropTypes.func.isRequired,
  commentId: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  isJournalComment: PropTypes.bool,
};

export default CommentCardDropdown;
