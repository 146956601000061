import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { AnswerPropType, CommentPropType } from 'lib/propTypes';
import { CREATE_REPLY_COMMENT, CREATE_COMMENT } from 'store/facilitatorDashboard/actions';
import { Button, Spinner } from 'react-bootstrap';
import TextareaField from 'components/shared/FormFields/Fields/TextareaField';
import WordCount from './WordCount';
import './styles.scss';

function Reply({ comment, onClose, isJournal, answerId }) {
  const dispatch = useDispatch();
  const { subGroupId, taskId } = useParams();
  const { fullName } = useSelector((state) => state.currentUser);
  const { error } = useSelector((state) => state.facilitatorDashboard);
  const initialValues = {
    subGroupId,
    taskId,
    replyToCommentId: isJournal ? answerId : comment.id,
    isJournal: isJournal || false,
    newComment: '',
  };

  const handleSubmit = useCallback((values, actions) => {
    const ACTION = isJournal ? CREATE_COMMENT : CREATE_REPLY_COMMENT;
    const meta = isJournal ? { answer: comment } : null;
    dispatch(ACTION.request(values, { ...meta, actions: { ...actions, handleClose: onClose } }));
  }, [comment, isJournal, onClose, dispatch]);

  return (
    <div className="reply-container">
      <div className="border-bottom p-2">
        {`Reply to ${comment.author} as ${fullName}`}
      </div>

      {error && (
        <p className="mb-1 px-2 text-small text-danger">
          {error}
        </p>
      )}

      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={Yup.object().shape({
          newComment: Yup.string().required('Required'),
        })}
      >
        {({ values, errors, isSubmitting }) => (
          <Form>
            <Field
              name="newComment"
              component={TextareaField}
              placeholder="Start typing here"
              className="reply-textarea d-block p-2"
            />
            {errors.newComment && <div className="form-text px-2 text-danger">{errors.newComment}</div>}

            <div className="d-flex align-items-center border-top p-2">
              <WordCount text={values.newComment} />

              <Button
                type="submit"
                variant="primary"
                className="ms-auto"
                disabled={isSubmitting}
              >
                Reply
                {isSubmitting && <Spinner size="sm" className="ms-1" animation="border" role="status" />}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

Reply.defaultProps = {
  isJournal: false,
  answerId: undefined,
};

Reply.propTypes = {
  comment: PropTypes.oneOfType([CommentPropType, AnswerPropType]).isRequired,
  onClose: PropTypes.func.isRequired,
  isJournal: PropTypes.bool,
  answerId: PropTypes.number,
};

export default Reply;
