import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { sortedDBNotifications } from 'store/facilitatorDashboard/selectors';
import Table from 'react-bootstrap/Table';
import DiscussionBoardRow from './DiscussionBoardRow';

function DiscussionBoardsTable() {
  const currentUser = useSelector((state) => state.currentUser);
  const currentFacilitator = useSelector((state) => state.facilitatorDashboard.currentFacilitator);
  const discussionBoardNotifications = useSelector((state) => sortedDBNotifications(state));
  const filteredDiscussionBoardsNotifications = useMemo(() => discussionBoardNotifications?.filter((db) => currentFacilitator?.id === db.assignedFacilitatorId), [currentFacilitator?.id, discussionBoardNotifications]);

  return (
    <Table bordered hover className="bg-white my-4 sn-table align-cells-start">
      <thead>
        <tr>
          <th>{}</th>
          <th className="text-center">Board</th>
          <th>Course</th>
          <th>Pod</th>
          <th>Starting</th>
          <th>Closing</th>
          <th>Extension</th>
          <th className="text-nowrap">Unread posts</th>
          <th>{}</th>
          <th>{}</th>
        </tr>
      </thead>
      <tbody>
        {filteredDiscussionBoardsNotifications?.map((db) => (
          <DiscussionBoardRow key={db.key} discussionBoard={db} />
        ))}

        {filteredDiscussionBoardsNotifications?.length === 0 && (
        <tr>
          <td colSpan="11">
            {`It looks like ${currentFacilitator?.id === currentUser?.id ? 'you haven’t' : `${currentFacilitator?.firstName} hasn’t`} been assigned any points`}
          </td>
        </tr>
        )}
      </tbody>
    </Table>
  );
}

export default DiscussionBoardsTable;
