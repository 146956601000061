import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { FACILITATOR_COURSE_PATH } from 'lib/routerPaths';
import { buildRoutePath } from 'lib/routerHelpers';
import { SHORT_DATE } from 'lib/dateFormats';
import { CoursePropType } from 'lib/propTypes';
import { selectCourseTypeName } from 'store/courseTypes/selectors';
import Button from 'react-bootstrap/Button';
import Badge from 'components/shared/Badge/Badge';
import CourseDropDown from './CourseDropDown';

function CourseRow({ course, setShowGroupsCourseId }) {
  const { id } = useParams();
  const courseTypeName = useSelector((state) => selectCourseTypeName(state, id));

  const handleGroupsClick = useCallback((e) => {
    e.target.blur();
    setShowGroupsCourseId(course.id);
  }, [course.id, setShowGroupsCourseId]);

  if (!course) return null;

  return (
    <tr className="table-row" key={course.id}>
      <td className="course-name">
        <Link className="link-dark text-decoration-none" to={buildRoutePath(FACILITATOR_COURSE_PATH, { courseId: course?.id })}>
          {courseTypeName}
        </Link>
        <span className="d-block fw-normal text-muted">{course.description}</span>
      </td>
      <td className="text-nowrap">{format(parseISO(course.createdAt), SHORT_DATE)}</td>
      <td>
        {!course.groupIds?.length && (
          <span className="text-muted">0 groups</span>
        )}
        {course.groupIds?.length > 0 && (
          <Button variant="primary" className="btn-plain p-0" onClick={handleGroupsClick}>
            {`${course.groupIds?.length} groups`}
          </Button>
        )}
      </td>
      <td className="status"><Badge title={course.status} /></td>
      <td>
        {formatDistanceToNow(parseISO(course.updatedAt), { addSuffix: true })}
      </td>
      <td>
        <CourseDropDown course={course} />
      </td>
    </tr>
  );
}

CourseRow.defaultProps = {
  course: null,
};

CourseRow.propTypes = {
  course: CoursePropType,
  setShowGroupsCourseId: PropTypes.func.isRequired,
};

export default CourseRow;
