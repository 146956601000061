import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Overlay, Popover } from 'react-bootstrap';
import SanyasIcon from 'components/shared/SanyasIcon';
import ParticipantInfo from './ParticipantInfo';

function ParticipantInfoPopover({ participantId, displayName, className }) {
  const [show, setShow] = useState(false);
  const target = useRef(null);

  return (
    <div className={className}>
      <Button variant="alt-primary" className="rounded-pill px-2 py-0 fw-semibold" ref={target} onClick={() => setShow(!show)} style={{ fontSize: '0.75rem' }}>
        <SanyasIcon name="profileInfo" className="pe-1" size="xl" />
        demographic info
      </Button>

      <Overlay
        target={target.current}
        placement="bottom-start"
        show={show}
        rootClose
        onHide={() => setShow(false)}
      >
        <Popover id={`participant-info-${participantId}`} className="participant-info-popover">
          <Popover.Body className="p-2">
            <ParticipantInfo participantId={participantId} displayName={displayName} popover onClose={() => setShow(false)} />
          </Popover.Body>
        </Popover>
      </Overlay>
    </div>
  );
}

ParticipantInfoPopover.defaultProps = {
  displayName: null,
  className: '',
};

ParticipantInfoPopover.propTypes = {
  participantId: PropTypes.number.isRequired,
  displayName: PropTypes.string,
  className: PropTypes.string,
};

export default ParticipantInfoPopover;
