import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { sortedJournalNotifications } from 'store/facilitatorDashboard/selectors';
import Table from 'react-bootstrap/Table';
import JournalRow from './JournalRow';

function JournalsTable() {
  const currentUser = useSelector((state) => state.currentUser);
  const journalNotifications = useSelector((state) => sortedJournalNotifications(state));
  const currentFacilitator = useSelector((state) => state.facilitatorDashboard.currentFacilitator);
  const filteredJournals = useMemo(() => journalNotifications?.filter((journal) => currentFacilitator?.id === journal.assignedFacilitatorId), [currentFacilitator?.id, journalNotifications]);

  return (
    <Table bordered hover className="bg-white my-4 sn-table align-cells-start">
      <thead>
        <tr>
          <th>{}</th>
          <th className="text-center">Journal</th>
          <th>Course</th>
          <th>Pod</th>
          <th>Starting</th>
          <th>Closing</th>
          <th>Extension</th>
          <th className="text-nowrap">Unread Posts</th>
          <th className="text-nowrap">Response %</th>
          <th>{}</th>
          <th>{}</th>
        </tr>
      </thead>
      <tbody>
        {filteredJournals?.map((journal) => (
          <JournalRow key={journal.key} journal={journal} />
        ))}

        {filteredJournals?.length === 0 && (
          <tr>
            <td colSpan="11">
              {`It looks like ${currentFacilitator?.id === currentUser?.id ? 'you haven’t' : `${currentFacilitator?.firstName} hasn’t`} been assigned any points`}
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
}

export default JournalsTable;
