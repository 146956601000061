import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import _filter from 'lodash/filter';
import _isEqual from 'lodash/isEqual';
import { ACTIVE_STATUSES, DEFAULT_FILTER_STATE } from 'lib/constants';
import { GroupPropType } from 'lib/propTypes';
import Button from 'react-bootstrap/Button';
import ParticipantsTable from 'components/groups/ParticipantsTable/ParticipantsTable';

function GroupParticipantsTabPage({ group }) {
  const groupMemberships = group.groupParticipations;
  const loading = !group;

  const subGroups = useSelector((state) => state.groupShow.subGroups, (sg) => sg.parentGroupId === group.id);
  const [filters, setFilters] = useState(DEFAULT_FILTER_STATE);
  const [filteredParticipants, setFilterParticipants] = useState([]);
  const [filteredUnassignedParticipants, setFilterUnassignedParticipants] = useState([]);
  const subGroupsValues = Object.values(subGroups);

  useEffect(() => {
    const assignedGroupMemberships = _filter(
      groupMemberships,
      (gm) => !gm.isFacilitator && !!gm.subGroupId,
    );

    const unassignedGroupMemberships = _filter(
      groupMemberships,
      (gm) => !gm.isFacilitator && !gm.subGroupId,
    );

    const filterParticipants = ((participants) => (
      participants
        .filter((p) => p.completion.includes(filters.status))
        .filter((p) => p.fullName.toLowerCase().includes(filters.query.toLowerCase()))
        .sort((a, b) => {
          if (a.fullName.toLowerCase() > b.fullName.toLowerCase()) return (filters.sort === 'desc' ? -1 : 1);
          if (a.fullName.toLowerCase() < b.fullName.toLowerCase()) return (filters.sort === 'desc' ? 1 : -1);
          return 0;
        })
    ));

    setFilterUnassignedParticipants(filterParticipants(unassignedGroupMemberships));

    if (assignedGroupMemberships) {
      setFilterParticipants(filterParticipants(assignedGroupMemberships));
    }
  }, [filters, groupMemberships]);

  const onFilter = useCallback((e, prop) => {
    const val = e.target.value;
    setFilters((f) => ({
      ...f,
      [prop]: val || '',
    }));
  }, []);

  const onClear = useCallback((e) => {
    e.preventDefault();
    setFilters((f) => ({
      ...f,
      query: '',
      status: '',
    }));
  }, []);

  if (loading) return <div className="mt-4">Loading&hellip;</div>;

  return (
    <>
      <h2>Participants</h2>

      <div className="row align-items-center mb-3 gx-3">
        <div className="col-md-3">
          <label htmlFor="filter-name" className="sr-only">Filter</label>
          <input
            id="filter-name"
            value={filters.query}
            type="text"
            className="form-control"
            placeholder="Filter for participant in this group"
            onChange={(e) => onFilter(e, 'query')}
          />
        </div>

        <div className="col-md-3">
          <label htmlFor="filter-state" className="sr-only">State</label>
          <select id="filter-state" value={filters.status} className="form-select" onChange={(e) => onFilter(e, 'status')}>
            <option value="" key="none">All states</option>
            {ACTIVE_STATUSES.map((s) => <option key={s[0]} value={s[0]}>{s[1]}</option>)}
          </select>
        </div>

        {(filters.query.length > 0 || filters.status.length) > 0 && (
          <div className="col-md-3">
            <Button size="sm" className="btn-plain" onClick={onClear}>
              <span className="fas fa-times me-1" />
              Clear filters
            </Button>
          </div>
        )}
      </div>

      {(subGroupsValues.length === 0 && filteredUnassignedParticipants.length === 0) && (
        <p className="alert alert-info mt-2">
          There are no participants currently registered to this group
          {!_isEqual(filters, DEFAULT_FILTER_STATE) && ', with the current filters applied'}
          .
        </p>
      )}

      <p className="text-secondary text-center">
        {(group.maxParticipants != null && group.maxParticipants > 0) ? (
          `${group.countableParticipants}/${group.maxParticipants} participants`
        ) : (
          `${group.countableParticipants} participants`
        )}
      </p>

      <ParticipantsTable
        subGroups={subGroupsValues}
        groupMemberships={filteredParticipants}
        unassignedGroupMemberships={filteredUnassignedParticipants}
        sortDirection={filters.sort}
        updateFilters={setFilters}
      />
    </>
  );
}

GroupParticipantsTabPage.defaultProps = {
  group: null,
};

GroupParticipantsTabPage.propTypes = {
  group: GroupPropType,
};

export default GroupParticipantsTabPage;
