import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { FACILITATOR_DISCUSSION_BOARDS_PATH, FACILITATOR_JOURNALS_PATH } from 'lib/routerPaths';
import { CHANGE_CURRENT_FACILITATOR, CHANGE_DASHBOARD_SORT_ORDER } from 'store/facilitatorDashboard/actions';
import { SORT_ORDER_TYPES } from 'store/facilitatorDashboard/reducer';
import { activatedFacilitators, notificationTotals } from 'store/facilitatorDashboard/selectors';
import Select from 'components/shared/FormFields/Elements/Select';

function formatViewAsLabel({ label }, { context }) {
  return context === 'value' ? (
    <>
      Viewing as
      <span className="ps-1 fw-semibold">{label}</span>
    </>
  ) : label;
}

function formatSortOrderLabel({ label }, { context }) {
  return context === 'value' ? (
    <>
      Sorted by
      <span className="ps-1 fw-semibold">{label}</span>
    </>
  ) : label;
}

function FacilitatorHeader() {
  const currentUser = useSelector((state) => state.currentUser);
  const { currentFacilitator, sortOrder } = useSelector((state) => state.facilitatorDashboard);
  const facilitators = useSelector((state) => activatedFacilitators(state));
  const { discussionBoards, journals } = useSelector((state) => notificationTotals(state));
  const dispatch = useDispatch();
  const location = useLocation();

  const handleCurrentFacilitatorChange = useCallback((e) => {
    dispatch(CHANGE_CURRENT_FACILITATOR.action({ id: e.value }));
    document.activeElement.blur();
  }, [dispatch]);

  const handleSortOrderChange = useCallback((e) => {
    dispatch(CHANGE_DASHBOARD_SORT_ORDER.action({ sortLabel: e.value }));
    document.activeElement.blur();
  }, [dispatch]);

  return (
    <div className="row align-items-center mt-4">
      {currentUser?.hasSuperFacilitatorAccess && (
        <div className="col-md-4">
          <label className="sr-only" htmlFor="dashboard-facilitator-select">View as</label>
          <Select
            id="dashboard-facilitator-select"
            onChange={handleCurrentFacilitatorChange}
            className="inline-block"
            value={currentFacilitator?.id}
            options={facilitators?.map((facilitator) => ({ value: facilitator.id, label: facilitator.fullName }))}
            formatOptionLabel={formatViewAsLabel}
          />
        </div>
      )}

      <div className="col-md-4">
        <label className="sr-only" htmlFor="dashboard-sort-order">Sort by</label>
        <Select
          id="dashboard-sort-order"
          onChange={handleSortOrderChange}
          className="inline-block"
          value={sortOrder}
          options={Object.values(SORT_ORDER_TYPES)}
          formatOptionLabel={formatSortOrderLabel}
          isSearchable={false}
        />
      </div>

      {[FACILITATOR_DISCUSSION_BOARDS_PATH, FACILITATOR_JOURNALS_PATH].includes(location.pathname) && (
        <div className="col fw-accent text-end text-xsmall text-secondary">
          {((location.pathname === FACILITATOR_DISCUSSION_BOARDS_PATH && discussionBoards > 0)
            || (location.pathname === FACILITATOR_JOURNALS_PATH && journals > 0)) && (
            <span className="me-1 text-accent-deep-grey text-uppercase">
              Unread posts:
            </span>
          )}

          {(location.pathname === FACILITATOR_DISCUSSION_BOARDS_PATH && discussionBoards > 0) && discussionBoards}
          {(location.pathname === FACILITATOR_JOURNALS_PATH && journals > 0) && journals}
        </div>
      )}
    </div>
  );
}

export default FacilitatorHeader;
