import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useWorkspace } from 'lib/hooks/useWorkspace';
import { ComponentPropType } from 'lib/propTypes';
import { RESET_LOGIN } from 'store/user/actions';
import { Button, Spinner } from 'react-bootstrap';
import { REDUX_STATUS } from 'lib/constants';
import Confirmation from './Modal/Confirmation';

// Roughly mimics a Formik/form field, but for displaying data only
function LoginField({ label, value, loginId, className, formLabel }) {
  const { id: workspace } = useWorkspace();
  const { status } = useSelector((state) => state.user.resetLogin);
  const dispatch = useDispatch();
  const [confirmOpen, setConfirmOpen] = useState(false);

  const handleLoginReset = useCallback(() => {
    dispatch(RESET_LOGIN.request({ workspace, id: loginId }));
  }, [loginId, workspace, dispatch]);

  return (
    <>
      <div className={`mb-2 ${className ?? ''}`}>
        <div className="d-flex align-items-center pb-1 ">
          <span className={`${formLabel ? 'fw-medium text-secondary' : 'd-block text-accent-grey'}`} role="presentation">{label}</span>

          <Button variant="light" size="sm" disabled={!loginId || status === REDUX_STATUS.PENDING} className="ms-2 py-0" onClick={() => setConfirmOpen(true)}>
            Reset
          </Button>
        </div>

        <span className="fw-semibold text-primary">{value}</span>

        {status === REDUX_STATUS.PENDING && (
          <Spinner variant="primary" className="ms-2" animation="border" size="sm" role="status" />
        )}
      </div>

      <Confirmation
        open={confirmOpen}
        close={() => setConfirmOpen(false)}
        text={(
          <>
            <div
              className="bg-sn-sand flush top py-1"
            >
              <p className="mb-0">
                Resetting the participant’s Public Display name will also send them an email with:
              </p>

              <ul className="mb-0">
                <li>example reasons for a reset (proper name or identifying information),</li>
                <li>instructions to change their pseudonym, and</li>
                <li>reference to ‘Meeting Your Group’ discussion.</li>
              </ul>
            </div>

            <p className="d-flex justify-content-between mt-3 mb-0">
              <strong>Confirm</strong>
              <span className="ps-2">Please confirm that you want to reset this participant’s Public Display name.</span>
            </p>
          </>
        )}
        confirmText="Yes, reset"
        confirmAction={handleLoginReset}
        cancelText="No"
      />
    </>
  );
}

LoginField.defaultProps = {
  value: null,
  loginId: null,
  className: null,
  formLabel: false,
};

LoginField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, ComponentPropType]),
  loginId: PropTypes.number,
  className: PropTypes.string,
  formLabel: PropTypes.bool,
};

export default LoginField;
