import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { AnswerPropType } from 'lib/propTypes';
import { buildRoutePath } from 'lib/routerHelpers';
import { FACILITATOR_JOURNAL_ANSWERS_PATH } from 'lib/routerPaths';
import { MARK_NOTIFICATION_AS_READ, MARK_NOTIFICATION_AS_UNREAD } from 'store/facilitatorDashboard/actions';
import { CARD_TYPES } from 'store/facilitatorDashboard/reducer';
import ParticipantInfoPopover from 'components/group_memberships/ParticipantInfoPopover';
import SanyasIcon from 'components/shared/SanyasIcon';
import MultilineText from 'components/shared/MultilineText';
import NotificationDot from 'components/shared/NotificationDot';
import ParticipantLink from './ParticipantLink';
import SubtitleDate from './SubtitleDate';
import MarkAsReadButton from './MarkAsReadButton';
import './styles.scss';

function JournalPreviewCard({ answer }) {
  const { subGroupId, taskId, id } = useParams();
  const dispatch = useDispatch();
  const { assignedFacilitatorId } = useSelector((state) => state.facilitatorDashboard);
  const currentUser = useSelector((state) => state.currentUser);
  const isAssignedFacilitationPoint = currentUser?.id === assignedFacilitatorId;

  const markComment = useCallback(() => {
    if (answer.notificationCleared) {
      dispatch(MARK_NOTIFICATION_AS_UNREAD.request({ id: answer.notificationId, subGroupId }, { cardType: CARD_TYPES.PREVIEW_CARD, priority: answer.notificationPriority, gmId: answer.participationId }));
    } else {
      dispatch(MARK_NOTIFICATION_AS_READ.request({ id: answer.notificationId, subGroupId }, { cardType: CARD_TYPES.PREVIEW_CARD, priority: answer.notificationPriority, gmId: answer.participationId }));
    }
    document.activeElement.blur();
  }, [answer, dispatch, subGroupId]);

  return (
    <div className="ms-5 mb-2 col-md-11">
      <div className="mb-3 pt-3 px-4">
        <div className="d-flex align-items-center justify-content-between position-relative">
          {!answer.notificationCleared && (
            <div className="position-absolute top-50 start-0 translate-middle" style={{ marginBottom: 2, paddingRight: '1.375rem' }}>
              <NotificationDot age={answer.notificationPriority} />
            </div>
          )}

          <div className="d-flex align-items-baseline fw-semibold">
            <ParticipantLink
              groupId={id}
              groupMembershipId={answer.participationId}
              participantName={answer.fullName}
            />

            <SubtitleDate className="ps-2" date={answer.createdAt} />
            <ParticipantInfoPopover participantId={answer.participationId} className="ms-3" />
          </div>

          {isAssignedFacilitationPoint && (
            <MarkAsReadButton post={answer} markAsRead={markComment} />
          )}
        </div>

        <MultilineText text={answer.answer} />

        <div className="d-flex justify-content-end mb-1">
          <Link
            to={buildRoutePath(FACILITATOR_JOURNAL_ANSWERS_PATH, { id, subGroupId, taskId, groupMembershipId: answer.participationId })}
            className="btn-plain"
          >
            <SanyasIcon name="chat" className="me-1 align-center" />
            Reply
          </Link>
        </div>
      </div>
    </div>
  );
}

JournalPreviewCard.defaultProps = {
};

JournalPreviewCard.propTypes = {
  answer: AnswerPropType.isRequired,
};

export default JournalPreviewCard;
