import { asyncAction, syncAction } from 'lib/actionCreatorCreators';

export const CHANGE_CURRENT_FACILITATOR = syncAction('CHANGE_CURRENT_FACILITATOR');
export const CHANGE_DASHBOARD_SORT_ORDER = syncAction('CHANGE_DASHBOARD_SORT_ORDER');
export const GET_DASHBOARD_FOR_FACILITATOR = asyncAction('GET_DASHBOARD_FOR_FACILITATOR');
export const GET_SECONDARY_DASHBOARD_DATA = asyncAction('GET_SECONDARY_DASHBOARD_DATA');
export const GET_SUB_GROUP_DATA = asyncAction('GET_SUB_GROUP_DATA');
export const LOAD_FACILITATOR_DASHBOARD_NOTIFICATIONS = asyncAction('LOAD_FACILITATOR_DASHBOARD_NOTIFICATIONS');
export const LOAD_COMMENTS = asyncAction('LOAD_COMMENTS');
export const LOAD_JOURNAL_ANSWERS = asyncAction('LOAD_JOURNAL_ANSWERS');
export const LOAD_PARTICIPANT_JOURNAL = asyncAction('LOAD_PARTICIPANT_JOURNAL');
export const FLAG_JOURNAL = asyncAction('FLAG_JOURNAL');
export const CREATE_REPLY_COMMENT = asyncAction('CREATE_REPLY_COMMENT');
export const CREATE_COMMENT = asyncAction('CREATE_COMMENT');
export const UPDATE_COMMENT = asyncAction('UPDATE_COMMENT');
export const UPDATE_COMMENT_FLAG = asyncAction('UPDATE_COMMENT_FLAG');
export const LOAD_INITIAL_STATE = asyncAction('LOAD_INITIAL_STATE');
export const MARK_NOTIFICATION_AS_READ = asyncAction('MARK_NOTIFICATION_AS_READ');
export const MARK_NOTIFICATION_AS_UNREAD = asyncAction('MARK_NOTIFICATION_AS_UNREAD');
export const LOAD_PARTICIPANT = asyncAction('LOAD_PARTICIPANT');
export const LOAD_PARTICIPANT_IWL_STATUS = asyncAction('LOAD_PARTICIPANT_IWL_STATUS');
export const DELETE_COMMENT = asyncAction('DELETE_COMMENT');
export const CREATE_FACILITATOR_NOTE = asyncAction('CREATE_FACILITATOR_NOTE');
export const UPDATE_FACILITATOR_NOTE = asyncAction('UPDATE_FACILITATOR_NOTE');
export const CREATE_ANSWER_COMMENT = asyncAction('CREATE_ANSWER_COMMENT');
export const UPDATE_ANSWER_COMMENT = asyncAction('UPDATE_ANSWER_COMMENT');
export const UPDATE_ANSWER_FLAG = asyncAction('UPDATE_ANSWER_FLAG');
export const GET_FACILITATOR_LIVE_FEED = asyncAction('GET_FACILITATOR_LIVE_FEED');
export const RESET_DASHBOARD_STATUS = syncAction('RESET_DASHBOARD_STATUS');
