import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { FLAG_COLOURS, POST_FLAGS } from 'lib/constants';
import { FLAG_JOURNAL, UPDATE_COMMENT_FLAG } from 'store/facilitatorDashboard/actions';
import SanyasIcon from 'components/shared/SanyasIcon';
import DefaultModal from 'components/shared/Modal/DefaultModal';
import { Button, Spinner } from 'react-bootstrap';
import SelectField from 'components/shared/FormFields/SelectField';

const FormSchema = Yup.object().shape({
  flag: Yup.string().oneOf(Object.values(POST_FLAGS), 'Invalid flag'),
});

const formatOptionLabel = ({ value }) => (
  <div className="d-flex align-items-baseline">
    <SanyasIcon name="squareFill" size="sm" className={`me-2 ${FLAG_COLOURS[value]}`} />
    {value}
  </div>
);

function FlagForm({ post, show, onClose }) {
  const params = useParams();
  const dispatch = useDispatch();
  const flagOptions = Object.values(POST_FLAGS).map((flag) => ({ value: flag, label: flag }));
  const initialValues = { flag: post.flag || (post.flagged ? POST_FLAGS.FLAGGED_FOR_REVIEW : '') };

  const saveFlag = useCallback(({ flag }, actions) => {
    if (post.userTaskId) {
      dispatch(FLAG_JOURNAL.request({
        subGroupId: params.subGroupId,
        gmId: params.groupMembershipId,
        userTaskId: post.userTaskId,
        taskId: params.taskId,
        flag: flag === '' ? null : flag,
      }, { formikActions: actions }));
    } else {
      dispatch(UPDATE_COMMENT_FLAG.request({ id: post.id, flag: flag === '' ? null : flag }, { formikActions: actions }));
    }
  }, [post, params, dispatch]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={saveFlag}
      validationSchema={FormSchema}
      enableReinitialize
    >
      {({ handleSubmit, isSubmitting }) => (
        <DefaultModal
          isOpen={show}
          header="Flag post?"
          onClose={() => onClose(false)}
          footerComponent={(
            <Button className="btn-primary" onClick={handleSubmit}>
              Flag
              {isSubmitting && <Spinner size="sm" className="ms-1" animation="border" role="status" />}
            </Button>
          )}
        >
          <Form>
            <p>Please choose which flag you would like to add. This will be seen by facilitators only.</p>

            <SelectField name="flag" placeholder="Select flag" options={flagOptions} formatOptionLabel={formatOptionLabel} />
          </Form>
        </DefaultModal>
      )}
    </Formik>
  );
}

FlagForm.defaultProps = {
  show: false,
  onClose: null,
};

FlagForm.propTypes = {
  post: PropTypes.shape({
    id: PropTypes.number,
    userTaskId: PropTypes.number,
    subGroupId: PropTypes.number,
    groupMembershipId: PropTypes.number,
    taskId: PropTypes.number,
    flag: PropTypes.string,
    flagged: PropTypes.bool,
  }).isRequired,
  show: PropTypes.bool,
  onClose: PropTypes.func,
};

export default FlagForm;
