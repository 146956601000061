import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AnswerPropType } from 'lib/propTypes';
import { MARK_NOTIFICATION_AS_READ, MARK_NOTIFICATION_AS_UNREAD } from 'store/facilitatorDashboard/actions';
import { CARD_TYPES } from 'store/facilitatorDashboard/reducer';
import { Button } from 'react-bootstrap';
import SanyasIcon from 'components/shared/SanyasIcon';
import MultilineText from 'components/shared/MultilineText';
import NotificationDot from 'components/shared/NotificationDot';
import ParticipantLink from './ParticipantLink';
import SubtitleDate from './SubtitleDate';
import MarkAsReadButton from './MarkAsReadButton';
import './styles.scss';

function JournalAnswerCard({ answer }) {
  const { id, subGroupId } = useParams();
  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state.currentUser);
  const { assignedFacilitatorId } = useSelector((state) => state.facilitatorDashboard);
  const isAssignedFacilitationPoint = currentUser?.id === assignedFacilitatorId;

  const markComment = useCallback(() => {
    if (answer.notificationCleared) {
      dispatch(MARK_NOTIFICATION_AS_UNREAD.request({ id: answer.notificationId, subGroupId }, { cardType: CARD_TYPES.ANSWER_CARD, priority: answer.notificationPriority, gmId: answer.participationId }));
    } else {
      dispatch(MARK_NOTIFICATION_AS_READ.request({ id: answer.notificationId, subGroupId }, { cardType: CARD_TYPES.ANSWER_CARD, priority: answer.notificationPriority, gmId: answer.participationId }));
    }
    document.activeElement.blur();
  }, [answer, dispatch, subGroupId]);

  return (
    <div className="mb-3">
      <div className="card bg-white shadow-sm rounded px-4">
        <div className="d-flex align-items-center justify-content-between position-relative my-2" style={{ minHeight: 28 }}>
          {!answer.notificationCleared && (
            <div className="position-absolute top-50 start-0 translate-middle" style={{ paddingRight: '1.375rem' }}>
              <NotificationDot age={answer.notificationPriority} />
            </div>
          )}

          <div className="d-flex align-items-baseline fw-semibold">
            <ParticipantLink
              groupId={id}
              groupMembershipId={answer.participationId}
              participantName={answer.fullName}
            />

            <SubtitleDate className="ps-2" date={answer.createdAt} />
          </div>

          {isAssignedFacilitationPoint && (
            <MarkAsReadButton post={answer} markAsRead={markComment} />
          )}

        </div>

        <MultilineText text={answer.body} className="mb-0 py-1" />

        <div className="d-flex justify-content-end my-2" style={{ minHeight: 28 }}>
          <Button
            disabled={!isAssignedFacilitationPoint}
            onClick={() => window.scrollTo(0, document.body.scrollHeight)}
            variant="plain"
            size="sm"
            className="me-1 px-1 fw-semibold"
          >
            <SanyasIcon name="chat" className="me-1" />
            Reply
          </Button>
        </div>
      </div>
    </div>
  );
}

JournalAnswerCard.defaultProps = {
  notificationId: undefined,
};

JournalAnswerCard.propTypes = {
  answer: AnswerPropType.isRequired,
  notificationId: PropTypes.number,
};

export default JournalAnswerCard;
