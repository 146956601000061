import React from 'react';
import { Link } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { SHORT_DATE } from 'lib/dateFormats';
import { JournalNotificationPropType } from 'lib/propTypes';
import { buildRoutePath } from 'lib/routerHelpers';
import { FACILITATOR_JOURNAL_PREVIEW_COMMENTS_PATH } from 'lib/routerPaths';
import PostFlag from 'components/shared/Comments/PostFlag';
import Badge from 'components/shared/Badge/Badge';
import QuicknotesPopover from 'components/facilitator/QuicknotesPopover';

function JournalRow({ journal }) {
  const {
    internalName,
    silenceJournalNotification,
    groupName,
    subGroupName,
    groupBeginSessionAt,
    groupCloseSessionAt,
    groupExtendedUntil,
    primary,
    replies,
    journalTaskId,
    groupId,
    subGroupId,
    responseRate,
    flags,
    note,
  } = journal;

  const journalPath = buildRoutePath(FACILITATOR_JOURNAL_PREVIEW_COMMENTS_PATH, { id: groupId, subGroupId, taskId: journalTaskId });

  return (
    <tr key={journalTaskId} className="text-small">
      <td className="pe-0">
        {flags?.map((flag) => <PostFlag key={journalTaskId + flag} flag={flag} />)}
      </td>
      <td className="position-relative text-center">
        <Link to={journalPath} className="btn-plain fw-semibold stretched-link">
          <Badge
            variant={internalName.toLowerCase()}
            title={internalName}
          />
        </Link>
      </td>
      <td>
        <Link to={journalPath} className="btn-plain fw-semibold">
          {groupName}
        </Link>
      </td>
      <td>{subGroupName}</td>
      <td className="text-nowrap">
        {format(parseISO(groupBeginSessionAt), SHORT_DATE)}
      </td>
      <td className="text-nowrap">
        {format(parseISO(groupCloseSessionAt), SHORT_DATE)}
      </td>
      <td className="text-nowrap">
        {!!groupExtendedUntil && format(parseISO(groupExtendedUntil), SHORT_DATE)}
      </td>
      <td className="text-nowrap text-secondary">
        {silenceJournalNotification && 'Silenced'}
        {!silenceJournalNotification && (
          <>
            <Badge
              variant="red"
              title={primary.highPriorityTotal}
              className="me-1"
            />
            <Badge
              variant="orange"
              title={primary.mediumPriorityTotal}
              className="me-1"
            />
            <Badge
              variant="green"
              title={primary.lowPriorityTotal}
              className="me-1"
            />
            <br />
            <Badge
              variant="red"
              title={replies.highPriorityTotal}
              className="me-1"
              alternate
            />
            <Badge
              variant="orange"
              title={replies.mediumPriorityTotal}
              className="me-1"
              alternate
            />
            <Badge
              variant="green"
              title={replies.lowPriorityTotal}
              className="me-1"
              alternate
            />
          </>
        )}
      </td>
      <td>
        {responseRate ? `${(responseRate * 100).toFixed(0)}%` : '0%'}
      </td>
      <td>
        <QuicknotesPopover note={note} />
      </td>
      <td className="position-relative text-center">
        <Link to={journalPath} className="btn-plain stretched-link">
          View
        </Link>
      </td>
    </tr>
  );
}

JournalRow.propTypes = {
  journal: JournalNotificationPropType.isRequired,
};

export default JournalRow;
