/* TODO Combine this file with client/components/groups/ParticipantsBulkImportReview/ReactSelectStyles.js */
import { SIZES } from 'lib/constants';

const fontSizes = {
  [SIZES.sm]: '0.875rem',
  [SIZES.md]: '1rem',
  [SIZES.lg]: '1.25rem',
};
const padding = {
  [SIZES.sm]: ['0.25rem 0.5rem', '0.25rem', '0.5rem'],
  [SIZES.md]: ['0.375rem 0.75rem', '0.375rem 0.25rem', '0.75rem'],
  [SIZES.lg]: ['0.5rem 1rem', '0.5rem 0.25rem', '1rem'],
};

export const ReactSelectStyles = (size) => ({
  clearIndicator: (base) => ({
    ...base,
    padding: padding[size][1],
  }),
  control: (base, state) => {
    // match Bootstrap form-select
    let borderColor = 'var(--bs-sn-clr-border-grey)';
    let boxShadowColor = 'var(--bs-primary-rgb)';
    if (state.isFocused && !state.selectProps['aria-invalid']) {
      borderColor = 'rgba(242, 247, 247, 0.55)';
    }
    if (state.selectProps['aria-invalid']) {
      borderColor = 'var(--bs-danger)';
      boxShadowColor = 'var(--bs-danger-rgb)';
    }
    return ({
      ...base,
      backgroundColor: state.isDisabled ? 'var(--bs-secondary-bg)' : base.backgroundColor,
      borderColor,
      '&:hover': { // Override react-select :hover style
        borderColor,
        cursor: 'pointer',
      },
      borderRadius: `var(--bs-border-radius${size !== SIZES.md ? `-${size}` : ''})`,
      boxShadow: state.isFocused && `0 0 0 0.25rem rgba(${boxShadowColor}, 0.25)`,
      fontSize: fontSizes[size],
      minHeight: size !== SIZES.md ? `calc(1.5em + ${size === SIZES.sm ? '0.5rem' : '1rem'} + calc(var(--bs-border-width) * 2))` : undefined,
    });
  },
  dropdownIndicator: (base) => ({
    // match Bootstrap form-select
    ...base,
    color: 'var(--bs-gray-800)',
    '&:hover': { // Override react-select :hover style
      color: 'var(--bs-gray-800)',
    },
    padding: padding[size][1],
    paddingRight: padding[size][2],
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  input: (base) => ({ ...base, margin: 0, padding: 0 }),
  menu: (base) => ({
    ...base,
    borderRadius: `var(--bs-border-radius${size !== SIZES.md ? `-${size}` : ''})`,
  }),
  menuPortal: (base) => ({
    ...base,
    zIndex: 10,
  }),
  option: (base, state) => ({ ...base,
    backgroundColor: (state.isSelected || state.isFocused) ? 'var(--bs-sn-clr-light-green)' : 'white',
    color: state.isSelected ? 'var(--bs-sn-clr-green)' : undefined,
    padding: `0.25rem ${padding[size][2]}`,
    '&:active': { // Override react-select :active option style
      backgroundColor: 'var(--bs-sn-clr-medium-green)',
      color: '#2D4548',
    },
  }),
  placeholder: (base) => ({ ...base, color: 'var(--bs-secondary-color)' }),
  singleValue: (base) => ({ ...base, margin: 0 }),
  valueContainer: (base) => ({ ...base, padding: padding[size][0] }),
});
